import styled from "styled-components";

export const CvListContainer = styled.div`
  padding: 25px;
  box-sizing: border-box;
`;

export const CvItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px dashed #414141;
`;

export const MenuRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0px;
  margin-left: auto;
  gap: 0px 5px;
`;

export const TagInput = styled.input`
  margin-left: 10px;
  padding: 5px;
  font-size: 14px;
`;

export const CvLists = styled.div`
    overflow:auto;
    max-height:90vh;
`

export const CvListContent = styled.div`
    padding-bottom:10vh;
`