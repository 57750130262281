import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../images/trash.svg";
import useFirestore from "../../hooks/useFirestore";
import toast from "react-hot-toast";
import TagList from "../TagList"; // Asegúrate de importar el nuevo componente
import { CvListContainer, CvItem, MenuRight, TagInput, CvLists, CvListContent} from "./styles"


export const CvList = ({
  data,
  setIsUpdating,
  setCurrentData,
  setName,
  setDocId,
}: any) => {
  const { saveData, updateData, deleteData } = useFirestore();
  const [tagInput, setTagInput] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const filteredData = data?.filter((cv: any) => {
    const filterLower = searchFilter.toLowerCase();
    return (
      cv.name.toLowerCase().includes(filterLower) ||
      (cv.tags &&
        cv.tags.some((tag: any) => tag.toLowerCase().includes(filterLower)))
    );
  });

  const handleEdit = (cv: any) => {
    const { data, id, name } = cv;
    setCurrentData(data);
    setName(name);
    setDocId(id);
  };

  const handleClone = async (cv: any) => {
    const { data, name, tags = [] } = cv;
    await saveData("cvs", {
      name: name + " (cloned) ",
      data: data,
      tags: [...tags],
    });
    setIsUpdating(true);
    toast.success(name + " fue clonado exitosamente");
  };

  const handleRemove = async (cv: any) => {
    const { id, name } = cv;

    const runDelete = async (t: any) => {
      toast.dismiss(t.id);
      await deleteData("cvs", id);
      setIsUpdating(true);
      toast.success(name + " fue eliminado correctamente");
    };

    toast(
      (t) => (
        <span className="toaster-container">
          <span>Usted va a eliminar el siguiente cv:</span>
          <span className="toaster-container-name">
            <b> {name} </b>
          </span>
          <span className="toaster-container-btn">
            <button onClick={() => runDelete(t)} className="btn-delete">
              Eliminar
            </button>
            <button onClick={() => toast.dismiss(t.id)} className="btn-cancel">
              No, Cancelar
            </button>
          </span>
        </span>
      ),
      {
        duration: Infinity,
      }
    );
  };

  const handleAddTag = async (cv: any, tag: any) => {
    if (!tag.trim()) return;

    const updatedTags = cv.tags ? [...cv.tags, tag.trim()] : [tag.trim()];
    // Actualiza el documento con los nuevos tags
    await updateData("cvs", cv.id, { tags: updatedTags });
    setTagInput("");
    setIsUpdating(true);
    toast.success("Tag añadido");
  };

  if (!data || data.length === 0) {
    return <>Loading</>;
  }
  return (
    <CvListContainer>
      <div className="header-title">
        <h2>Listado de Cvs</h2>
        <div className="search-cv">
          <input
            type="text"
            placeholder="Buscar candidato"
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </div>
      </div>
      <CvLists className="scroller">
        <CvListContent>
          {filteredData?.map((cv: any) => {
            return (
              <CvItem>
                <div className="cv-naming">{cv.name}</div>
                <TagList cv={cv} updateData={updateData} />
                <MenuRight>
                  <button className="new-button" onClick={() => handleEdit(cv)}>
                    Edit
                  </button>
                  <button className="new-button" onClick={() => handleClone(cv)}>
                    Clone
                  </button>
                  <button
                    className="image-button"
                    onClick={() => handleRemove(cv)}
                  >
                    <Trash />
                  </button>
                </MenuRight>
              </CvItem>
            );
          })}
        </CvListContent>
      </CvLists>
    </CvListContainer>
  );
};
